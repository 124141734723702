<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <!-- <v-card-text> -->
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="add_page()"
                >
                  Dodaj
                </v-btn>
              </v-col>
            </v-row>

          <!-- </v-card-text> -->
        </v-card>
        
        <v-form v-model="valid">
          <v-card>
            <v-card-title>Dodawanie strony</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>

                  <v-text-field
                    v-model="name"
                    label="Tytuł strony"
                    color="primary"
                  ></v-text-field>

                  <vue-editor v-model="description"></vue-editor>

                  <v-file-input
                    v-model="files"
                    truncate-length="15"
                    chips
                    multiple
                    label="Załącz pliki"
                  ></v-file-input>

                  <v-switch
                    v-model="public_page"
                    label="Czy opublikować"
                  ></v-switch>
                
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>

      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  data: () => ({
    id: 0,
    valid: false,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Baza wiedzy',
        disabled: false,
        to: '/baza-wiedzy-lista',
      },
      {
        text: 'Kategoria',
        disabled: false,
        to: '/baza-wiedzy/kategoria',
      },
      {
        text: 'Dodawanie strony',
        disabled: true,
        to: '/edit',
      }
    ],


    name: '',
    description: "",
    public_page: false,

    files: null
  }),
  methods: {
    add_page() {
      this.$store.commit('loader');

      let formData = new FormData();
      formData.append("title", this.name);
      formData.append("description", this.description);
      formData.append("categories_id", this.id);
      if(this.public_page) formData.append("published", 1);
      else formData.append("published", 0);

      if(this.files){
        for(let file in this.files){
          formData.append("files["+file+"][file]", this.files[file]);
        }
      }

      console.log(this.files);

      this.$axios({url: this.$store.state.api +'/faq', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          this.$router.push('/baza-wiedzy/kategoria/'+this.id);
          this.$store.commit("snackbar", {
            text: "Dodano kategorie",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    },
  },
  mounted(){
    this.id = this.$route.params.id;
    this.breadcrumb[2].to = '/baza-wiedzy/kategoria/'+this.id;
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
</style>